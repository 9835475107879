<template>
  <div class="form">
    <div class="form-group">
      <label>
        {{ $t("pages.applications.credentials.form.client_id") }}
      </label>
      <div>
        {{ clientId }}
        <a href="#" @click="copyToClipboard(clientId)">
          <mpx-icon :variant="'file-copy'"></mpx-icon>
        </a>
      </div>
    </div>
    <div class="form-group">
      <label>
        {{ $t("pages.applications.credentials.form.client_secret") }}
      </label>
      <div v-if="clientSecret">
        {{ clientSecret }}
        <a href="#" @click="copyToClipboard(clientSecret)">
          <mpx-icon :variant="'file-copy'"></mpx-icon>
        </a>
      </div>
      <div v-if="clientSecret" class="description">
        {{
          $t(
            "pages.create_hmac_auth_application.form.client_secret.description"
          )
        }}
      </div>
      <div v-if="!clientSecret">
        {{ $t("pages.applications.credentials.value.hidden_client_secret") }}
      </div>
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-primary field-submit"
        v-on:click="regenerateSecretHmacAuthApplication"
      >
        {{
          isRegeneratedApplication
            ? $t(
                "pages.regenerate_secret_hmac_auth_applications.form.button.saving"
              )
            : $t(
                "pages.regenerate_secret_hmac_auth_applications.form.button.update"
              )
        }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import {
  APPLICATION_REGENRATE_SECRET_HMAC_AUTH,
  APPLICATION_SET_NAME,
} from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

interface Data {
  isRegeneratedApplication: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data(): Data {
    return {
      isRegeneratedApplication: false,
    };
  },
  computed: {
    applicationName: {
      // getter
      get: function () {
        return this.$store.state.application.hmacAuthApplication?.name;
      },
      // setter
      set: function (name: string) {
        this.$store.commit(APPLICATION_SET_NAME, name);
      },
    },
    clientId(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientId;
    },
    clientSecret(): string | undefined {
      return this.$store.state.application?.hmacAuthApplication?.clientSecret;
    },
  },
  methods: {
    async regenerateSecretHmacAuthApplication() {
      if (this.isRegeneratedApplication) {
        return;
      }

      this.isRegeneratedApplication = true;

      const metadata = {
        Remarks: "Success",
        Reason: "Success Regenerate Secret",
        CompanySSOID: this.$store.state.application.hmacAuthApplication?.companyId
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Success Regenerate Secret",
      }

      try {
        await this.$store.dispatch(APPLICATION_REGENRATE_SECRET_HMAC_AUTH, {
          uuid: this.$store.state.application.hmacAuthApplication?.id,
        });
        mixpanelInstance.setMetadata(metadata);
        emit(event.RegenerateSecretApplication, dataEvent);
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
        ? (err as any).response.data.message
        : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.RegenerateSecretApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.isRegeneratedApplication = false;
      }
    },

    async copyToClipboard(text: string | any) {
      await navigator.clipboard.writeText(text);
    },

    handleError(err: Error | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: err.message,
        type: ToastType.ERROR,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";
.page-content {
  .form-group {
    label {
      margin-bottom: 4px;
    }

    .description {
      font-weight: normal;
      font-size: 12px;
      color: $color-gray-slate;
    }
  }
}
</style>
